import { builderData, APOData, templateJSON } from "./BuilderProps";
import { displayProperty } from "./DisplayProperty";
import { bgImageField, AddAccordion } from "./PropHTML";
import { SignupformFields } from "./Signupform";

// Utility to set alignment type
const setAlignmentType = (widgetName) => {
  const type = widgetName !== "Text" ? "flex" : "";
  document.querySelectorAll(".proAlignment").forEach((item) => {
    item.dataset.type = type;
  });
};

// Initialize background image properties
export const TemplateSettingProp = () => {
  const propConfig = {
    whereInsert: ".insertTemplateProp",
    label: {
      text: "Background image",
      ischeckbox: true,
    },
    urlType: "TemplateBG",
    applyTO: false,
  };
  bgImageField(propConfig);
};

// Open row properties
export const openRow_Property = () => {
  const propConfig = {
    whereInsert: ".insertRowProp",
    label: {
      text: "Row Background image",
      ischeckbox: true,
    },
    urlType: "rowBG",
    applyTO: true,
  };
  bgImageField(propConfig);
};

// Open module properties
export const openModule_Property = () => {
  const WName = builderData.widgetName;
  setAlignmentType(WName);
  switch (WName) {
    case "Button":
      displayProperty(["propWidth", "propAlignment"]);
      ButtonStyle(WName);
      break;
    case "Image":
    case "BrandLogo":
      displayProperty(["propWidth", "propAlignment", "propImageUpload"]);
      ImageStyle();
      break;
    case "TextEditor":
      displayProperty([]);
      break;
    case "Footer":
      displayProperty(["propAlignment"]);
      break;
    case "BusinessHours":
    case "BusinessAddress":
    case "ReviewList":
    case "PrivacyPolicy":
    case "Terms":
    case "Cookies":
      displayProperty([]);
      ContentStyle(WName);
      break;
    case "Menu":
      displayProperty(["propAlignment"]);
      MenuStyle();
      break;
    case "Service":
    case "SelectedService":
    case "stafflist":
    case "OrderDetail":
      openServiceProperties(WName);
      break;
    case "AppointmentSlot":
    case "Calendar":
      displayProperty(["SwitchStep"]);
      WName === "Calendar" ? CalendarStyle(WName) : AppointmentSlotStyle(WName);
      break;
    case "Booking":
      displayProperty(["propWidth", "propAlignment"]);
      BookingStyle(WName);
      break;
    case "BookingSearch":
      displayProperty(["propWidth", "propAlignment"]);
      BookingSearchStyle(WName);
      break;
    case "Signup":
      displayProperty(["SwitchStep", "propWidth", "propAlignment"]);
      SignupStyle(WName);
      break;
    case "TabWidget":
      displayProperty(["propWidth", "propAlignment"]);
      TabwidgetStyle(WName);
      break;
    default:
      break;
  }
};

// Button style handler
const ButtonStyle = async (WName) => {
  const buttonstyle = builderData.editWidget.querySelector(".buttonstyle");
  const style = await GetTextstylingFN(buttonstyle, "buttonstyle");

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      textInput: {
        type: "text",
        label: "Button Text",
        applyTO: "btnText",
        value: buttonstyle.innerText,
      },
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Background Color",
          applyTO: "buttonstyle",
          BGColorProp: style.Stylejson["backgroundColor"],
        },
      ],
      border: {
        label: "Border",
        applyTO: "borderStyle",
      },
      borderRadius: {
        label: "Border radius",
        applyTO: "borderStyle",
        border: "rounded-e-none border-r-0",
        infobox: true,
        type: "borderRadius",
        width: "80px",
      },
    },
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
    },
  ];
  AddAccordion(widgetOption, style);
};

// Image style handler
const ImageStyle = () => {
  const propConfig = {
    whereInsert: ".insertModuleProp",
    label: {
      text: "Select image",
      ischeckbox: false,
    },
    urlType: "widgetSRC",
    applyTO: false,
  };
  bgImageField(propConfig);
};

// Content style handler
const ContentStyle = async (WName) => {
  const { Textstyling, Titlestyling, Substyling } =
    templateJSON.StyleObj[WName];

  const widgetOption = [
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
      tabs: [],
    },
  ];

  // Create an array to store the available styles
  const availableStyles = [];

  if (Textstyling) {
    availableStyles.push({
      text: "Content",
      icon: "fa-text-width",
      applyTO: "Textstyling",
      Stylejson: Textstyling,
    });
  }

  if (Titlestyling) {
    availableStyles.push({
      text: "Heading",
      icon: "fa-heading",
      applyTO: "Titlestyling",
      Stylejson: Titlestyling,
    });
  }

  if (Substyling) {
    availableStyles.push({
      text: "Sub heading",
      icon: "fa-heading",
      applyTO: "Substyling",
      Stylejson: Substyling,
    });
  }

  // If only one style is available, use it directly with AddAccordion
  if (availableStyles.length === 1) {
    const singleStyle = availableStyles[0];
    const Stylejson = {
      Stylejson: singleStyle.Stylejson,
      applyTO: singleStyle.applyTO,
    };
    AddAccordion(widgetOption, Stylejson);
  } else {
    // If more than one style is available, add them to the widgetOption tabs
    widgetOption[0].tabs = availableStyles;
    AddAccordion(widgetOption, false);
  }
};

// Menu List style handler
const MenuStyle = async () => {
  let Tstyling = [];
  for (const x of builderData.editWidget.querySelectorAll(".Textstyling")) {
    if (!x.classList.contains("active")) {
      Tstyling.push(x);
      break;
    }
  }
  let Stylejson = await GetTextstylingFN(Tstyling[0], "Textstyling");
  Stylejson.activecolor = builderData.editWidget.querySelector(".active").style.color; // active color

  const widgetOption = [
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: true,
        isLineH: false,
      },
    },
  ];
  AddAccordion(widgetOption, Stylejson);
};

// Open service-specific properties
const openServiceProperties = (WName) => {
  let showProp = ["SwitchStep"];
  const serviceStep = document.querySelector(".serviceStep");

  if (!serviceStep.childElementCount) {
    const { steps } = templateJSON.StyleObj[WName];
    steps.forEach((step, i) => {
      const option = document.createElement("option");
      option.value = i + 1;
      option.text = `Step ${i + 1}`;
      option.dataset.template = step.name;
      option.selected = step === APOData.APO_selectedData.serviceActiveStep;
      serviceStep.append(option);
    });
  }

  if (WName === "Service" && serviceStep.value === "1") {
    showProp.push("ServiceFilter");
  }

  displayProperty(showProp);
  ServiceStyle(WName);
};

// Service style handler
export const ServiceStyle = async (WName) => {
  const { Textstyling, Titlestyling, layout } = templateJSON.StyleObj[WName];
  const { APOtype, appointmentFilter } = APOData;

  $(".serviceLayoutFilter").val(layout);

  // update filter appointment dropdown
  const filterAppointment = document.querySelector(".filterAppointment");
  filterAppointment.innerHTML = "";
  // Create a new option element and  Add in filter select
  appointmentFilter.forEach(function (filter) {
    const option = document.createElement("option");
    option.value = filter;
    option.text = filter;

    if (filter === APOtype) {
      option.selected = true;
    }

    filterAppointment.append(option);
  });

  $(".S_customSelect").customSelect("reset"); // reset custom select

  const widgetOption = [
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
      tabs: [
        {
          text: "Content",
          icon: "fa-text-width",
          applyTO: "Textstyling",
          Stylejson: Textstyling,
        },
        {
          text: "Heading",
          icon: "fa-heading",
          applyTO: "Titlestyling",
          Stylejson: Titlestyling,
        },
      ],
    },
  ];
  AddAccordion(widgetOption, false);
};

const CalendarStyle = async (WName) => {
  const { backgroundColor } = templateJSON.StyleObj[WName].active;
  const widgetOption = [
    {
      title: `Decorations`,
      whereInsert: ".insertAccordion-MProp",
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Selected date color",
          applyTO: "active",
          BGColorProp: backgroundColor,
        },
      ],
    },
  ];
  AddAccordion(widgetOption, false);
};

const AppointmentSlotStyle = async (WName) => {
  const BreakH_ele = builderData.editWidget.querySelectorAll(".breakHour")[0];
  const { Timeslots, breakHour } = templateJSON.StyleObj[WName];

  const widgetOption = [
    {
      title: `Decorations`,
      whereInsert: ".insertAccordion-MProp",
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Background Color",
          applyTO: "Timeslots",
          BGColorProp: Timeslots.backgroundColor,
        },
      ],
    },
  ];
  if (!!BreakH_ele) {
    widgetOption[0].backgroundColor.push({
      type: "backgroundColor",
      label: "Break hours color",
      applyTO: "breakHour",
      BGColorProp: breakHour.backgroundColor,
    });
  }

  AddAccordion(widgetOption, false);
};

const BookingStyle = async (WName) => {
  const { servicetitle, Textstyling, borderStyle } =
    templateJSON.StyleObj[WName];

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      border: {
        label: "Border",
        applyTO: "borderStyle",
      },
    },
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
      tabs: [
        {
          text: "Content",
          icon: "fa-text-width",
          applyTO: "Textstyling",
          Stylejson: Textstyling,
        },
        {
          text: "Heading",
          icon: "fa-heading",
          applyTO: "servicetitle",
          Stylejson: servicetitle,
        },
      ],
    },
  ];

  AddAccordion(widgetOption, { Stylejson: borderStyle });
};

const BookingSearchStyle = async (WName) => {
  const { widgetstyle } = templateJSON.StyleObj[WName];
  const widgetOption = [
    {
      title: `From style`,
      whereInsert: ".insertAccordion-MProp",
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Background Color",
          applyTO: "widgetstyle",
          BGColorProp: widgetstyle.backgroundColor,
        },
      ],
    },
  ];
  await AddAccordion(widgetOption, false);
};

const SignupStyle = async (WName) => {
  const { widgetstyle, Textstyling, inputstyle, form } =
    templateJSON.StyleObj.Signup;
  const margeStyle = {
    Stylejson: { ...widgetstyle, ...Textstyling, ...inputstyle },
    applyTO: "Textstyling",
  };

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      backgroundColor: [
        {
          type: "backgroundColor",
          label: "Background Color",
          applyTO: "widgetstyle",
          BGColorProp: margeStyle.backgroundColor,
        },
      ],
      border: {
        label: "Border",
        applyTO: "inputstyle",
      },
      borderRadius: {
        label: "Border radius",
        applyTO: "inputstyle",
        border: "rounded-e-none border-r-0",
        infobox: true,
        type: "borderRadius",
        width: "80px",
      },
    },
  ];

  if (form.fields[0].label.show) {
    widgetOption.splice(1, 0, {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: false,
        isLineH: true,
      },
    });
  }

  await AddAccordion(widgetOption, margeStyle);
  const fieldHtml = await SignupformFields();
};

const TabwidgetStyle = async (WName) => {
  const { widget__tabtitle, active } = templateJSON.StyleObj[WName];
  const margeStyle = {
    Stylejson: { ...widget__tabtitle, activecolor: active.color },
    applyTO: "widget__tabtitle",
  };

  const widgetOption = [
    {
      title: `${WName} option`,
      whereInsert: ".insertAccordion-MProp",
      border: {
        label: "Border",
        applyTO: "borderStyle",
      },
    },
    {
      title: "Typography",
      whereInsert: ".insertAccordion-MProp",
      Typography: {
        ActiveTextcolor: true,
        isLineH: false,
      },
    },
  ];
  await AddAccordion(widgetOption, margeStyle);
};

const GetTextstylingFN = async (element, TO) => {
  const Tstyling = element.style;
  let style = {
    Stylejson: {
      fontWeight: Tstyling.fontWeight,
      fontSize: Tstyling.fontSize.replace(/[px%]/g, ""),
      fontFamily: Tstyling.fontFamily.replace(/["]/g, ""),
      letterSpacing: Tstyling.letterSpacing.replace(/[px%]/g, ""),
      borderWidth: Tstyling.borderWidth.replace(/[px%]/g, ""),
      borderRadius: Tstyling.borderRadius.replace(/[px%]/g, ""),
      borderStyle: Tstyling.borderStyle,
      borderColor: Tstyling.borderColor,
      color: Tstyling.color,
      backgroundColor: Tstyling.backgroundColor,
      lineHeight: Tstyling.lineHeight,
    },
    applyTO: TO,
  };
  return style;
};

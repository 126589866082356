import { v4 as uuidv4 } from "uuid";
import { PostAPI_PutAPI_method } from "./Api";
import { APOData, templateJSON } from "./BuilderProps";
import { registerHelper } from "./Handlebars-helper";
import Handlebars from "handlebars";
import moment from "moment";

export const FontFamilylist = [
  "Poppins",
  "Abril Fatface",
  "Alata",
  "Arial",
  "Bitter",
  "Cabin",
  "Courier New",
  "Droid Serif",
  "Georgia",
  "Helvetica Neue",
  "Inter",
  "Lato",
  "Lucida Sans Unicode",
  "Merriweather",
  "Montserrat",
  "Nunito",
  "Open Sans",
  "Oswald",
  "Oxygen",
  "Permanent Marker",
  "Roboto",
  "Roboto Slab",
  "Source Sans Pro",
  "Tahoma",
  "TimesNewRoman",
  "Trebuchet MS",
  "Ubuntu",
  "Verdana",
];

window.singleTeamMethod = (event) => {
  event.preventDefault();
}
window.serviceBackStep = (event) => {
  event.preventDefault();
}

// Get URL parameters as an instance of URLSearchParams
export const getUrlParams = async (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const fontFoption_Html = async (selected) => {
  return FontFamilylist.map(
    (items) =>
      `<option value="${items}" ${
        items == selected && "selected"
      } class="${items.replace(
        /[^A-Z0-9]+/gi,
        ""
      )}" style="font-family: ${items}">${items}</option>`
  ).join("");
  // let option = '';
  // FontFamilylist.forEach((items) => {
  //   option += `<option value="${items}" class="${items.replace(/[^A-Z0-9]+/gi, "")}" style="${items}">${items}</option>`
  // });
  // return option;
};

export const base64EncodeUnicode_HTML = (str) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  var utf8Bytes = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match, p1) {
      return String.fromCharCode("0x" + p1);
    }
  );
  return btoa(utf8Bytes);
};

export const base64DecodeUnicode_HTML = (str) => {
  // Convert Base64 encoded bytes to percent-encoding, and then get the original string.
  const percentEncodedStr = atob(str)
    .split("")
    .map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
    .join("");

  return decodeURIComponent(percentEncodedStr);
};

// save template html
const topbarSave = document.querySelector(".topbar-save");
topbarSave.addEventListener("click", async function (event) {
  try {
    setBtnLoader(true, event.currentTarget, [21]);
    const themeType = parseInt(await getUrlParams("ThemeType"));
    const isPublished = true;
    const themeId = await getUrlParams("id");
    const insertData = document.getElementById("insertData");

    // remove element-hightlighted class
    const hightlighted = insertData.querySelectorAll(".element-hightlighted");
    hightlighted.forEach((element) => {
      element.classList.remove("element-hightlighted");
    });

    // remove CK editor instances and ID
    destroyALLCKinstances(insertData);

    const postData = {
      html: base64EncodeUnicode_HTML(insertData.innerHTML),
      styleJsonMetaData: JSON.stringify(templateJSON),
      isPublished,
      [themeType ? "id" : "fk_LKP_ThemeId"]: themeId,
    };

    const response = await PostAPI_PutAPI_method(
      "MyThemes",
      themeType ? "PUT" : "POST",
      postData
    );
    if (response.data.success) {
      const { onboardingData } = APOData;
      location.href = onboardingData.editorCallBackUrl;
    } else {
      setBtnLoader(false, event.currentTarget);
      console.error("Something went wrong.. Please try again");
    }
  } catch (error) {
    setBtnLoader(false, event.currentTarget);
    console.error("An error occurred while saving the theme:", error);
  }
});

export const updateHandlebarsTemp = async (element, templateFunc, data) => {
  // Compile Handlebars template
  const compiledTemplate = Handlebars.compile(templateFunc);
  registerHelper();
  const html = compiledTemplate(data);
  element.innerHTML = html;
};

// Function to convert time string to minutes
export const convertTimeToMinutes = (timeString, split = /h|m/) => {
  const [hoursStr, minutesStr] = timeString
    .split(split)
    .map((str) => str.trim());
  const hours = parseInt(hoursStr) || 0;
  const minutes = parseInt(minutesStr) || 0;
  // const totalMinutes = (type == 'Service' || type == 'Appointment')?  hours * 60 + minutes : hoursStr;
  const totalMinutes = hoursStr;
  return totalMinutes;
};

// Function to convert timestamp to minutes
export const convertUnixTimestamp = (timestamp, format) => {
  return moment.unix(timestamp).format(format);
};

export const momentDateTimeFormat = (data, format) => {
  return moment(new Date(data)).format(format);
};
// Appy BG color and Image on method
export function applyC_toElement(element, property, bgType = "BGcolor") {
  element.forEach((items) => {
    bgType == "BGcolor"
      ? (items.style.backgroundColor = property)
      : (items.style.backgroundImage = `url(${property})`);
  });
}

// update widget style JSON
export const updateStyleObj = (element, WName, value) => {
  const { applyto, propname } = element.dataset;
  if (!!templateJSON.StyleObj[WName]) {
    templateJSON.StyleObj[WName][applyto][propname] = value;
  }
};

export const findSuperParentWithClass = (element, className) => {
  // Check if the element itself has the class
  if (element.classList.contains(className)) {
    return element; // Return the element itself
  }
  var parent = element.parentNode;
  while (parent !== null) {
    if (parent.classList.contains(className)) {
      return parent; // Found the super parent with the class
    }
    parent = parent.parentNode; // Move to the next parent node
  }
  return null; // Super parent with the class not found
};

export const mouserOver_method = async () => {
  const addHoverClass = (element, className) => {
    element.classList.add(className);
    const iconSort = element.querySelector(".icon-sort");
    iconSort ? iconSort.innerHTML = `<i class="fa-solid fa-up-down-left-right"></i>` : '';
  };
  const removeHoverClass = (element, className) => element.classList.remove(className);
  // const addHandle = (container, checkEle, html) => {
  //   if (container.querySelector(checkEle) === null) {
  //     container.insertAdjacentHTML('afterbegin', html);
  //   }
  // };
  //const removeHandles = (container, selector) => container.querySelectorAll(selector).forEach(item => item.remove());

  const addModuleHover = (module) => {
    module.addEventListener("mouseover", (evt) => {
      evt.stopPropagation();
      addHoverClass(module, "widget--hover");
      const rowContainer = module.closest(".row-container-outer");
      removeHoverClass(rowContainer, "row-container-outer--hover");
      //addHandle(module, '.column_handle',  '<button type="button" class="icon-sort column_handle"><i class="fa-solid fa-up-down-left-right"></i></button>');
    });

    module.addEventListener("mouseleave", () => {
      //removeHandles(module, '.column_handle');
      removeHoverClass(module, "widget--hover");
    });
  };

  const rowContainerOuter = document.querySelectorAll(".row-container-outer");
  for (const rowContainer of rowContainerOuter) {
    if (!rowContainer.id) {
      rowContainer.id = "APO" + uuidv4();
    }
    rowContainer.addEventListener("mouseover", () => {
      addHoverClass(rowContainer, "row-container-outer--hover");
      //addHandle(rowContainer, '.row-selector',  '<div class="row-selector"><button type="button" class="icon-sort row_handle"><i class="fa-solid fa-up-down-left-right"></i></button></div>')
      // if (rowContainer.querySelector('.row-selector') === null) {
      //   rowContainer.insertAdjacentHTML('afterbegin', '<div class="row-selector"><button type="button" class="icon-sort row_handle"><i class="fa-solid fa-up-down-left-right"></i></button></div>');
      // }
    });

    rowContainer.addEventListener("mouseleave", () => {
      //removeHandles(rowContainer, '.row-selector');
      removeHoverClass(rowContainer, "row-container-outer--hover");
    });

    const widgetmodules = rowContainer.querySelectorAll(".widget");
    for (const module of widgetmodules) {
      if (!module.id) {
        const genrateID = uuidv4();
        module.id = "APO" + genrateID;
      }
      const textED = module.querySelectorAll(".textED-content");
      if (textED.length) {
        textED.forEach((element, i) => {
          if (!element.id) {
            element.id = `textE${i}-${module.id}`;
            window.ckinlinEditor(element);
          }
        });
      }

      addModuleHover(module);

      const builderElements = module.querySelectorAll("[builder-element]");
      for (const builderE of builderElements) {
        if (!builderE.id) {
          builderE.id = "APO" + uuidv4();
        }
        builderE.addEventListener("mouseover", (evt) => {
          evt.stopPropagation();
          addHoverClass(builderE, "widget--hover");
          removeHoverClass(module, "widget--hover");
          removeHoverClass(rowContainer, "row-container-outer--hover");
        });

        builderE.addEventListener("mouseleave", () => {
          removeHoverClass(builderE, "widget--hover");
        });
      }
    }
  }
};

// export const initTextEditor = (element, id) => {
//   const textED = element.querySelectorAll('.textED-content');
//   if (textED.length) {
//     textED.forEach((element, i) => {
//       element.id = `textE${i}-${id}`;
//       window.ckinlinEditor(element);
//     });
//   }
// }

// remove CK editor instances and ID
export const destroyALLCKinstances = (element) => {
  const Check_CKinstances = element.querySelectorAll(".textED-content");
  Check_CKinstances.forEach((CKeditor) => {
    window.destroyCKEditor(CKeditor.id);
    CKeditor.removeAttribute("id");
  });
};

export const setScreenmask = (isLoading) => {
  const APO__screenmask = document.querySelector(".APO__screenmask");
  if (isLoading === "remove") {
    APO__screenmask.remove();
  } else {
    APO__screenmask.classList[isLoading === "show" ? "remove" : "add"](
      "hidden"
    );
  }
};

export const setBtnLoader = (isLoading, targetElement, size = [16]) => {
  if (!targetElement) return; // Ensure targetElement is valid

  if (!isLoading) {
    const originalContent = targetElement.getAttribute("data-original-content");
    if (originalContent) {
      targetElement.innerHTML = originalContent;
    }
    targetElement.removeAttribute("data-original-content");
    targetElement.disabled = false; // Re-enable the button
  } else {
    if (!targetElement.getAttribute("data-original-content")) {
      targetElement.setAttribute(
        "data-original-content",
        targetElement.innerHTML
      );
    }
    const loaderHtml = `<span class="APO__loader APO__spin block mx-auto" style="width:${size[0]}px;"></span>`;
    targetElement.innerHTML = loaderHtml;
    targetElement.disabled = true; // Disable the button to prevent multiple clicks
  }
};

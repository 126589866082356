import {
  openModule_Property,
  openRow_Property,
  TemplateSettingProp,
} from "./ShowModuleStyle";
import { builderData } from "./BuilderProps";
import { mouserOver_method } from "./CommonMethod";
import { onFlyProps_remove } from "./PropertySetting";
// import { v4 as uuidv4 } from "uuid";
const propertyContent = document.querySelector(".property__component");
const editRowprop = document.querySelector(".editRowprop");
const emptyRow = document.querySelector(".emptyRow");

(function () {
  // open widget/row property
  $(document).on("click", "[builder-element]", function (e) {
    e.stopPropagation();
    $("[builder-element], .row-container-outer").removeClass("element-hightlighted");
    this.classList.add("element-hightlighted");
    const properclsList = propertyContent.classList;
    if (builderData.editWidget == "" || builderData.editWidget !== this) {
      Object.assign(builderData, {
        editWidget: this,
        widgetName: this.dataset.title.trim().replace(/\s+/g, ""),
      }); // update edit widget data

      if (properclsList.contains("open")) {
        properclsList.remove("open");
        setTimeout(() => {
          properclsList.add("open");
        }, 300);
      } else {
        properclsList.add("open");
      }
      onFlyProps_remove();
      openModule_Property(); // open widget property
    }
  });

  $(document).on("click", ".row-container-outer", function (e) {
    $("[builder-element], .row-container-outer").removeClass("element-hightlighted");
    this.classList.add("element-hightlighted");
    Object.assign(builderData, {
      editWidget: this,
      widgetName: this.dataset.title.trim().replace(/\s+/g, ""),
    }); // update edit widget data

    emptyRow.classList.add("hidden");
    editRowprop.classList.remove("hidden");
    propertyContent.classList.remove("open"); // hide property slide
    document.getElementById("rowPropertiesTab").click(); // triger Row property tab
    onFlyProps_remove();
    openRow_Property(); // open widget property
  });

  // main tabs click method
  const mainTabs = document.querySelectorAll(".mainTabs a");
  for (const action of mainTabs) {
    action.addEventListener("click", function () {
      if (this.id == "WidgelistTab") {
        managePropSlide();
      } else if (this.id == "templateSettingTab") {
        //$(".defaultFontFm").customSelect();
        managePropSlide();
        onFlyProps_remove();
        TemplateSettingProp();
      }
    });
  }

  // Close property slide
  const slideClose = document.querySelectorAll(".slideClose");
  for (const close of slideClose) {
    close.addEventListener("click", function () {
      managePropSlide(); // manage property slide
      onFlyProps_remove();
    });
  }

  // copy module
  const widgetCopy = document.querySelectorAll(".widgetCopy");
  for (const Mcopy of widgetCopy) {
    Mcopy.addEventListener("click", function () {
      let cloneNode = builderData.editWidget.cloneNode(true);
      cloneNode.id = "";
      const textED = cloneNode.querySelectorAll('.textED-content');
      if (textED.length) {
        textED.forEach(item => {
          item.id = "";
        });
      }
      //cloneNode.querySelectorAll('.textED-content').forEach(items => items.id = "textE" + cloneNode.id);
      builderData.editWidget.insertAdjacentElement("afterend", cloneNode);
      mouserOver_method(); // show class to row and widge on hover
    });
  }

  // delete module
  const widgetDelete = document.querySelectorAll(".widgetDelete");
  for (const Mdelete of widgetDelete) {
    Mdelete.addEventListener("click", function () {
      const textED = builderData.editWidget.querySelectorAll(".textED-content");
      builderData.editWidget.remove();
      Object.assign(builderData, { editWidget: "", widgetName: "" }); //reset edit widget data
      managePropSlide(); // manage property slide
      mouserOver_method(); // show class to row and widge on hover
      if (textED.length) {
        textED.forEach((element) => {
          window.destroyCKEditor(element.id);
        });
      }
    });
  }
})();

// manage property slide
export const managePropSlide = () => {
  emptyRow.classList.remove("hidden"); // show Empty Row
  editRowprop.classList.add("hidden"); // hide Row property slide
  propertyContent.classList.remove("open"); // hide property slide

  //remove hightlighted class
  if (builderData.editWidget) {
    builderData.editWidget.classList.remove("element-hightlighted");
  }
  
  Object.assign(builderData, { editWidget: "", widgetName: "" }); //reset edit widget data
};

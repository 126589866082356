import { APOData, templateJSON } from "./BuilderProps";
import { updateHandlebarsTemp } from "./CommonMethod";
import { get__ServiceEventClass } from "./Service";

(async function () {
  // $(".S_customSelect").customSelect();

  // service layout filter
  $(".serviceLayoutFilter").change(async function () {
    templateJSON.StyleObj["Service"].layout = this.value; // set service type globally
    const { APOtype } = APOData;
    await appointmentLayout(APOData[APOtype]);
  });

})();

window.filterAppointment = async (event) => {
  const { target } = event;

  // Remove "selected" class from all <li> elements
  document.querySelectorAll(".appointmentFilter li").forEach((li) => {
    li.classList.remove("selected");
  });

  // Add "selected" class to the clicked element
  target.classList.add("selected");

  // Update the filter data
  const selectedFilter = event.type === "change" ? target.value : target.dataset.filter;
  templateJSON.StyleObj["Service"].APOtype = selectedFilter;
  APOData.APOtype = selectedFilter;

  await appointmentLayout(APOData[selectedFilter]);
};

export const appointmentLayout = async (result) => {
  // Fetch appointment data based on the selected value
  let appointmentData = (result && result.length)  ? result :  await get__ServiceEventClass();
  //if (appointmentData.length) {
    const { APOtype, appointmentFilter } = APOData;

    const appendElement = document.querySelector(`.Service .APO_booking_content`);
    const template = document.getElementById(`template_service${templateJSON.StyleObj["Service"].layout}`).innerHTML;

    const matchingType = appointmentFilter.find(items => items.typeName === APOtype);
    
    const templateData = {
      appointmentData,
      APOtypeName: matchingType.displayName,
      appointmentFilter,
      IsBookAppointmentVisible: true,
      APOtype
    }

    await updateHandlebarsTemp(appendElement, template, templateData); // Compile Handlebars template method

    $(".S_customSelect").customSelect();
  //}

  // display team list
  displayTeamList();
};

const displayTeamList = async () => {
  const { onboardingData } = APOData;

  const appendElement = document.querySelector(`.TeamList .team-wrapper`);
  const template = document.getElementById(`template_teamList`).innerHTML;

  await updateHandlebarsTemp(appendElement, template, { 
    teamList: onboardingData.staffMembersList,
  }); // Compile Handlebars template method

}

export const appointmentTypeList = async (data) => {
  try {
    // Update appointment filter based on provided conditions
    const APOtypeList = [
      { condition: data.isServiceAdded, typeName: "Service", displayName: "Services"},
      { condition: data.isClassAdded, typeName: "Class", displayName: "Classes" },
      { condition: data.isEventAdded, typeName: "Event", displayName: "Events" },
      { condition: data.isGlobalEventAdded, typeName: "GlobalEvents", displayName: "Event planning" },
    ];

    // Apply the filtered results
    APOData.appointmentFilter = APOtypeList.filter((item) => item.condition);
    APOData.APOtype = APOData.appointmentFilter[0].typeName;

  } catch (error) {
    console.error("Error setting appointment types:", error);
  }
}

import { builderData } from "./BuilderProps"; 
import { updateStyleObj } from "./CommonMethod";

export const onFlyProps_remove = () => {
  const onFly = document.querySelectorAll(".onFly");
  for (const ele of onFly) {
    ele.remove();
  }
};

export const applyBackgroundImage = (value, applyOn) => {
  const widgetElement = builderData.editWidget;

  if (applyOn === "widgetSRC") {
    // Update the src attribute for the element with class "updatesrc"
    widgetElement.querySelector(".updatesrc").src = value;
  } else {
    switch (applyOn) {
      case "rowBG": {
        const selectedToggleButton = document.querySelector(".rowBG-wrapper .ToggleButton.selected");

        if (selectedToggleButton) {
          const whereApply = selectedToggleButton.dataset.apply;
          // Set background image based on the 'whereApply' value
          widgetElement.style.backgroundImage = whereApply === "rowBG" ? `url(${value})` : "none";
          widgetElement.querySelector(".row-content").style.backgroundImage = whereApply === "rowBG" ? "none" : `url(${value})`;
        }
        break;
      }

      default:
        // Apply background image to the main container if no specific rule is matched
        if (builderData.mainCtnr && builderData.mainCtnr[0]) {
          builderData.mainCtnr[0].style.backgroundImage = `url(${value})`;
        }
        break;
    }
  }
};

(async function () {

  // upload row BG image
  window.updateBtnText = (event, element) => { 
    const btnText = builderData.editWidget.querySelector(".btnText");
    btnText.innerText = element.value;
  }
  // upload row BG image
  $(document).on("keyup blur", ".upImageURL", function () {
    const applyOn = this.dataset.apply;
    applyBackgroundImage(this.value, applyOn);
    
  });

  // Alignment pro-align
  const proAlignment = document.querySelectorAll(".proAlignment");
  for (const Malign of proAlignment) {
    Malign.addEventListener("click", function () {
      proAlignment.forEach((btn) => btn.classList.remove("selected")); // remove selected
      this.classList.add("selected"); // add selected
      const AlignStyle = builderData.editWidget.querySelector(".AlignStyle"); // alignment element
      const align = this.dataset.align; // align type
      if (this.dataset.type == "flex") {
        AlignStyle.style.justifyContent =
          align == "left"
            ? "flex-start"
            : align == "right"
            ? "flex-end"
            : "center"; // set align
      } else {
        AlignStyle.style.textAlign = align; // set align
      }
    });
  }

  // line height pro
  window.propLineH = (ele) => {
    let propElement = ele.parentElement;
    Array.from(propElement.children).forEach((btn) => btn.classList.remove("selected")); // remove selected
    ele.classList.add("selected"); // add selected lineHeight
    //const parentElement = (!!builderData.editWidget.steps) ? builderData.editWidget.steps : builderData.editWidget; // check service steps
    const {applyto, line} = ele.dataset;

    const element = builderData.editWidget.querySelectorAll(`.${applyto}`); // lineheight element

    updateStyleObj(ele, builderData.widgetName, line); // update widget style JSON 
    
    for (const applyele of element) {
      applyele.style.lineHeight = line;
    }
  };

  // font size/wieght
  $(document).on("change", ".propDropdown", function () {
    applyFpropTOele(this, this.value); // apply font property
  });

  // input number value on blur
  $(document).on("keyup blur", ".numberF-prop input", function () {
    let this_value = this.value.replace(/[^0-9]/g, "");
    this.value = this_value == "" ? 1 : this_value;
    //const propname  = this.dataset.propname;
    applyFpropTOele(this, this.value + 'px'); // apply font property
  });

  // number input plusminus
  $(document).on("click", ".numberF-prop button", function () {
    let numberField = this.parentElement.previousElementSibling;
    this.classList.contains("btn-sub")
      ? numberField.stepUp()
      : numberField.stepDown();

      //const propname  = numberField.dataset.propname;
      applyFpropTOele(numberField, numberField.value + 'px'); // apply font property
  });

  // apply font property
  const applyFpropTOele = (element, value) => {
    //const parentElement = (!!builderData.editWidget.steps) ? builderData.editWidget.steps : builderData.editWidget; // check service steps
    const {applyto, propname}  = element.dataset;
    const target = builderData.editWidget.querySelectorAll(`.${applyto}`);
    updateStyleObj(element, builderData.widgetName, value); // update widget style JSON 
    for (const Fprop of target) {
      Fprop.style[propname] = `${value}`;
    }
  }
})();

import StarRating from "star-rating.js";
import "star-rating.js/dist/star-rating.css"; // Import the CSS for styling
import { updateHandlebarsTemp } from "./CommonMethod";

export const initializeReviews = () => {
  new StarRating(".give-star-rating", {
    stars: 5, // Number of stars
    value: 0, // Default rating value
    tooltip: false, // Show tooltip on hover
    readOnly: false, // Whether the rating is read-only
    clearable: true,
    maxStars: 5,
    prebuilt: false,
    stars: null,
    callback: (newRating, el) => {
      //console.log(`Selected rating: ${newRating}`);
    },
  });

  SubmittedReviewList();
};

const SubmittedReviewList = async () => {
  // Select the booking wrapper element
  const userReviewsList = document.querySelector(".userReviewsList");

  // Get the template for booked appointment list
  const template = document.getElementById(
    "template_userReviewsList"
  ).innerHTML;

  const demoReview = [{
    email: "rackham@gmail.com",
    name: "H. Rackham",
    rating: 3,
    review: "TjLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.his",
  }];

  // Update the booking wrapper with the new content
  await updateHandlebarsTemp(userReviewsList, template, {
    UserReviews: demoReview,
  });

  const useRatedStar = document.querySelectorAll(".user-rated-star");
  useRatedStar.forEach((items) => {
    new StarRating(items, {
      stars: 5, // Number of stars
      tooltip: false, // Show tooltip on hover
      readOnly: true, // Whether the rating is read-only
      clearable: true,
      maxStars: 5,
      prebuilt: false,
      stars: null,
      callback: (newRating, el) => {
        //console.log(`Selected rating: ${newRating}`);
      },
    });
  });
};

import { APOData } from "./BuilderProps";
import { updateHandlebarsTemp, convertTimeToMinutes } from "./CommonMethod";
import { GetAPI_deleteAPI_method } from "./Api";
import moment from "moment";
import { toZonedTime } from 'date-fns-tz'

const fetchSelectedStaffData = async () => {
    try {
      const { staffJSON } = APOData.APO_selectedData;
      
      // Call the API to get events based on the staff ID
      const bookingList = await GetAPI_deleteAPI_method(`EventsByStaffId?StaffId=${staffJSON.id}`, "GET");
  
      // Validate the API response to ensure it contains data
      if (!bookingList?.data || !bookingList.data.length) {
        return [];
      }
  
      // Update staff's business hours list if available in the API response
      staffJSON.businessHoursList = bookingList.data[0]?.businessHoursList ?? [];
  
      // Return the fetched booking list data
      return bookingList.data;
  
    } catch (error) {
      console.error("Error fetching Selected Staff Data:", error);
    }
  };

  const APO_previewDate = async (businessHours) => {
    const {businessTimeZone} = APOData;
    const selectedDay = toZonedTime(moment().format(), businessTimeZone);
    
    const today = moment(selectedDay).format("dddd");
    const todayIndex = businessHours.findIndex((day) => day.day === today);
    const dayMap = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6,
    };
  
    for (let i = 0; i < businessHours.length; i++) {
      const dayIndex = (todayIndex + i) % businessHours.length;
      const dayData = businessHours[dayIndex];
  
      if (dayData.isOpened) {
        const daysToAdd = (dayMap[dayData.day] - todayIndex + 7) % 7;
        return moment(selectedDay).clone().add(daysToAdd, "days");
      }
    }
  
    return null; // No open days found
  };


// Function to initialize the date picker component
export const initdatepicker = async () => {
    await fetchSelectedStaffData();

    const { APO_selectedData, businessTimeZone } = APOData;
    const {businessHoursList} = APO_selectedData.staffJSON;
    
    // find the open day from provider business hours
    const openedDay = await APO_previewDate(businessHoursList);
    APO_selectedData.timeslot = (openedDay) ? moment().unix() : null;
    
    // let selectedDay = moment(new Date());
    APO_selectedData.date = openedDay;
  
   // Generate a comma-separated list of disabled days based on business hours
    const disabledDays = businessHoursList
      .map((hours, index) => (!hours.isOpened ? (index + 1) % 7 : null))
      .filter((day) => day !== null)
      .join(",");
  
    // Initialize the date picker with the desired configuration
    $("#datepicker")
      .datepicker({
        daysOfWeekDisabled: disabledDays,
        format: "mm/dd/yyyy",
        startDate: toZonedTime(moment().format(), businessTimeZone),
        weekStart: 1,
        todayHighlight: false,
        templates: {
          leftArrow: '<i class="fa fa-angle-left"></i>',
          rightArrow: '<i class="fa fa-angle-right"></i>',
        },
        beforeShowDay: function (date) {
          //return {
          //  content: `<span>${new Date(date).getDate()}</span>`
          //}
        },
      })
      .on("changeDate", function (e) {
        // Update the selected day based on user input
        // const selectedDay = e.date;
        // APO_selectedData.date = moment(e.date).format();

        let selectedDay = new Date(e.date);
        let updateSelectedDay = moment();
        // Update the moment date with the new date
        updateSelectedDay.year(selectedDay.getFullYear())
        .month(selectedDay.getMonth())
        .date(selectedDay.getDate());
  
        APO_selectedData.date = moment(updateSelectedDay).format();
  
        // Remove the 'today' class from all days in the date picker to avoid styling conflicts
        document.querySelectorAll(".datepicker-days .day").forEach((day) => day.classList.remove("today"));
  
        // Update service duration based on the newly selected day
        serviceDuration(updateSelectedDay);
      });
  
    // Set the date picker to display the initially selected day
    if (openedDay) {
        $("#datepicker").datepicker("setDate", openedDay.format("l"));
    }
    //serviceDuration(selectedDay, selectedAPO.duration, businessHoursList); // check serive duration
  };

  // Function to check service duration
const serviceDuration = (selectedDay) => {
    const { APOtype, APO_selectedData } = APOData;
    const { staffJSON, appointmentJSON } = APO_selectedData
  
    // Get day name from selectedDay (e.g., "Monday")
    const dayName = selectedDay.format("dddd");

    // Find business hours for the given day
    const businessHours = staffJSON.businessHoursList.find((item) => item.day === dayName);

    // Return early if no business hours are found or if the business is closed
    if (!businessHours || !businessHours.isOpened) return;

    // Destructure business hours properties
    const { from, to, breakHours } = businessHours;

    // Format start and end times in "HH:mm" format
    // const startTime = moment(from).format("HH:mm");
    // const endTime = moment(to).format("HH:mm");

    // Convert the appointment duration to minutes
    const durationInMinutes = APOtype === "Event" ? "30" : convertTimeToMinutes(appointmentJSON.duration, APOtype);
    const timeData = { 
        from, 
        to, 
        duration: durationInMinutes, 
        breakHours 
    };

    // Generate service time slots for the given day using time data
    generateServiceTimeSlots(timeData, selectedDay);
  };

  // Function to calculate service time slots
const generateServiceTimeSlots = async (timeData, selectedDay) => {
    const { from, to, duration, breakHours } = timeData;
  
    // Check if the selected day is today and compare the current time
    const isToday = moment().format("DD") === selectedDay.format("DD");
    const isTodayDate = moment().format("l") <= selectedDay.format("l");
    const currentTime = moment().format("HH:mm");
    // const isStartTimePassed = currentTime >= startTime;

    const availableServiceSlots = [];
    
    // Initialize timeSlot with the start time, and iterate until it reaches the end time
    let timeSlot = moment(from, "HH:mm");
    const endMoment = moment(to, "HH:mm");

    // while (timeSlot.add(duration, 'minutes').isBefore(endMoment)) {
    while (timeSlot.isBefore(endMoment)) {
      const slotTime = timeSlot.format("HH:mm");

      // Check if the current slot falls within a break or booked slot
      const { isBreak, breakEndTime } = isWithinBreakTime(slotTime, breakHours);
  
      if (!isToday || !isTodayDate || currentTime < slotTime) {
        const fullTimestamp = moment(selectedDay).set({
          hour: timeSlot.hour(),
          minute: timeSlot.minute(),
        });
  
        availableServiceSlots.push({
          slot: slotTime,
          timestamp: fullTimestamp.unix(),
          break: isBreak,
        });
    }
  
    // If the slot is within a break, skip to the end of the break; otherwise, increment by duration
    timeSlot = isBreak ? moment(breakEndTime, "HH:mm") : timeSlot.clone().add(duration, "minutes");

    }
  
    // Select the DOM element where the appointment slots will be rendered
    const SlotEle = document.querySelector(".Service .AppointmentSlot");
  
    // Get the Handlebars template from the script element
    const template = document.getElementById(`template_AvailableSlot`).innerHTML;

    // Compile and update the Handlebars template with the prepared data
    await updateHandlebarsTemp(SlotEle, template, {Bookingslots: availableServiceSlots});
  };

  // Function to check if the time is within break hours
const isWithinBreakTime = (time, breakHours) => {
    if (!breakHours || breakHours.length === 0) return { isBreak: false, breakEndTime: null };
    let breakInfo = { isBreak: false, breakEndTime: null };
  
    // Iterate through break times and find if the time falls within any break period
    breakHours.forEach((breakTime) => {
      const breakStartTime = moment(breakTime.from).format("HH:mm");
      const breakEndTime = moment(breakTime.to).format("HH:mm");
      if (time >= breakStartTime && time < breakEndTime) {
        breakInfo = { isBreak: true, breakEndTime: breakEndTime };
      }
    });
    return breakInfo;
  };